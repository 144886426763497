body {
  font-family: 'Open Sans', sans-serif;
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 1.5s; /* Firefox < 16 */
   -ms-animation: fadeout 1.5s; /* Internet Explorer */
    -o-animation: fadeout 1.5s; /* Opera < 12.1 */
       animation: fadeout 1.5s;
}

@keyframes fadeout {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from { opacity: 0; }
  to   { opacity: 1; }
}


/* Full-width input fields */
#pin-home {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  text-align: center;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

/* Set a style for all buttons */
.btn-home {
  background-color: #04AA6D;
  color: white;
  padding: 14px 20px;
  margin: 4px 0;
  border: none;
  cursor: pointer;
  font-size: 100%;
  text-align: center;
  font-weight: 300;
}

/* Center the image and position the close button */
.imgcontainer-home {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}
@media only screen and (max-width: 600px) {
  .logo-home{
    width: 60vw;
    height: auto;
  }
}

@media only screen and (min-width: 600px) {
  .logo-home{
    width: 60vw;
    height: auto;
  }
}

@media only screen and (min-width: 768px) {
  .logo-home{
    width: 60vw;
    height: auto;
  }
}

@media only screen and (min-width: 992px) {
  .logo-home{
    width: 60vw;
    height: auto;
  }
}

img.avatar {
  width: 40%;
  border-radius: 50%;
}

.container-home {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
}

span.pin-home {
  float: right;
  padding-top: 16px;
}

/* The Modal (background) */
.modal-home {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #fff; /* Fallback color */
  /* background-color: rgba(0,0,0,0.4); Black w/ opacity */
  padding-top: 60px;
}

/* Modal Content/Box */
.modal-content-home {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 90%; /* Could be more or less, depending on screen size */
}

/* Add Zoom Animation */
.animate-home {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
  from {-webkit-transform: scale(0)} 
  to {-webkit-transform: scale(1)}
}
  
@keyframes animatezoom {
  from {transform: scale(0)} 
  to {transform: scale(1)}
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.pin-home {
     display: block;
     float: none;
  }
}