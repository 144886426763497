body {
    background-color: fff;
    -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1.5s; /* Firefox < 16 */
     -ms-animation: fadein 1.5s; /* Internet Explorer */
      -o-animation: fadein 1.5s; /* Opera < 12.1 */
         animation: fadein 1.5s;
  }
  
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* Internet Explorer */
  @-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  
  table {
    border: 1px solid rgb(95, 94, 94);
    cursor: pointer;
  }
  
  th, td {
    text-align: left;
    padding: 8px;
  }
  
  tr:hover {
    background-color: #f2f2f2 !important;
  }
  tr:nth-child(even){
    background-color: #f2f2f2 !important;
  }
  tr:nth-child(odd){
    background-color: #fff ;
  }
  
  th {
    background-color: #04AA6D;
    color: white;
  }
  
  tr.selected {
    background-color: #51c097 !important;
  }
  
  #planTrasy {
    grid-area: trasa;
    background-color: #81909c;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 2px;
    word-spacing: 2px;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    text-align: left;
    color: #000000;
    width: 100vh;
    -webkit-box-shadow: 8px 8px 5px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 5px 0px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 5px 0px rgba(66, 68, 90, 1);
  }
  
  #planik {
    padding: 10px;
  }
  
  span{
    cursor: pointer;
  }

  h3{
    width:80%
  }
  
  /* Telefon */
  
  @media only screen and (max-width: 1300px) {
    .tabmap {
  
      display: grid;
      align-items: stretch;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      grid-template-columns: 100vw;
      grid-template-rows: 100vh;
      grid-template-areas: 
        "header"
        "mapka"
        "trasa";  
    }
  
    .tabeleczka {
      -webkit-overflow-scrolling:auto !important;
      grid-area: header;
      background-color: #dfdad5de;
      border: 1px solid rgb(95, 94, 94);
      font-family: 'Open Sans', sans-serif;
      font-size: 4vw;
      letter-spacing: 2px;
      word-spacing: 2px;
      font-variant: normal;
      text-transform: none;
      text-align: left;
      color: #000000;
      font-weight: 300;
      height: 100vh;
      padding-left:20px;
      padding-top:10px;
      padding-right:20px;
      /*padding-bottom:20px;*/
      border-radius: 10px;
      }
  
      #mapid {
        height: 100vh;
        grid-area: mapka;
        border-radius: 10px;
        border: 1px solid rgb(95, 94, 94);
      }
      
      .modal-content {
        background-color: #fefefe;
        margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
        border: 1px solid #888;
        width: 80vw; /* Could be more or less, depending on screen size */
      }
      
      input[type=datetime-local] {
        width: 70vw;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        box-sizing: border-box;
      }
      #tabelka_filter label{
        font-size: 1.2em;
        visibility: hidden;
      
      }
      #tabelka_filter label input{
        float:right;
        font-size: 1.2em;
        visibility: visible;
        width: 50%;
      }
  }
  
  /* Komputer */
  @media only screen and (min-width: 1300px) {
    .tabmap {
      display: grid;
      align-items: stretch;
      grid-template-columns: 35vw 65vw;
      grid-template-rows: 100vh;
      grid-template-areas: 
        "header mapka"
        "header trasa";  
    }
  
    .tabeleczka {
      grid-area: header;
      background-color: #dfdad5de;
      border: 1px solid rgb(95, 94, 94);
      font-family: 'Open Sans', sans-serif;
      font-size: 0.8vw;
      letter-spacing: 2px;
      word-spacing: 2px;
      font-style: normal;
      font-variant: normal;
      text-transform: none;
      text-align: left;
      color: #000000;
      margin-right: 10px;
      margin-left: 10px;
      /* margin-top: 0px !important; */
      height: 100vh;
      padding-left:20px;
      padding-top:10px;
      padding-right:20px;
     /* padding-bottom:20px;*/
      border-radius: 10px;
      }
  
      #mapid {
        height: 100vh;
        grid-area: mapka;
        border-radius: 10px;
        border: 1px solid rgb(95, 94, 94);
      }
      
      .modal-content {
        background-color: #fefefe;
        margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
        border: 1px solid #888;
        width: 30vw; /* Could be more or less, depending on screen size */
      }
      
      input[type=datetime-local] {
        width: 25vw;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        box-sizing: border-box;
      }
      #tabelka_filter label{
        font-size: 1.2em;
      }
      #tabelka_filter label input{
        font-size: 1.2em;
        visibility: visible;
      }
  }
  .dataTables_wrapper .dataTables_filter input{
    background-color: #FFFFFF;
  }
  #tabelka_filter{
    float: right;
    padding-bottom: 10px;
    padding-top: 5px;
    padding-right: 15px;
  }
  
  h1 {
    float: right;
    font-family: 'Open Sans', sans-serif;
    font-size: 1vw;
    font-weight: 200;
  }
  
  h2 {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1vw;
    font-weight: 500;
  }
  

  #dalej #wstecz {
    background-color: #04AA6D;
  /*  -webkit-border-radius: 10px;
    -moz-border-radius: 10px;*/
    border-radius: 24px;
    color: #FFFFFF;
    font-family: 'Open Sans', sans-serif;
    font-size: 1em;
    padding: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    -webkit-box-shadow: 1px 1px 5px 0 #000000;
    -moz-box-shadow: 1px 1px 5px 0 #000000;
    box-shadow: 1px 1px 5px 0 #000000;
    text-shadow: 1px 1px 5px #000000;
    border: solid #04AA6D 1px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    font-weight: 300;
  }
  
  button:hover{
    opacity: 0.8;
  }
  
  iframe {
    width: 95%;
    float: center;
    height: 800px;
    margin: auto;
    position: relative;
    display: block;
  }
  
  #srapka {
    position: relative;
  }
  
  #rameczka {
    background-color: #dfdad5de;
    border: 1px solid rgb(95, 94, 94);
    border-radius: 10px;
    width: 65vw;
    display: block;
    padding-bottom: 10px;
    margin: auto;
  }
  
  #stations {
    background-color: rgba(239, 239, 239, 0.8);
    border-radius: 3px;
    position: absolute; 
    bottom: 10px;
    left: 10px; 
    padding-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
    display: none;
    z-index: 405
  }
  
  #loading {
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(239, 239, 239, 0.8);
    z-index: 1000;
    -webkit-transition: top 1s;
    -moz-transition: top 1s;
    -o-transition: top 1s;
    transition: top 1s;
  }
  
  #loading-image {
    z-index: 407;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
  }
  
  .loader {
    border: 16px solid #d8d7d7;
    border-top: 16px solid #04AA6D;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: spin 2s linear infinite;
    z-index: 1001;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .logout{
    position: absolute;
    margin-top: 5px;
    z-index: 999;
    margin-bottom: 0px;
  } 
  
  .imgcontainer {
    text-align: center;
    margin: 24px 0 12px 0;
    position: relative;
  }
  
  #root{    
    width:100%;
    height: 100%;
  }
  .container {
    padding: 16px;
    width:100%;
    height: 100%;
  }
  
  .kontener {
    padding: 16px;
    height: 30%;
  }

  .kontener>h3{
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .kontener>input{
    border: none;
    background-color: #FFFFFF;
    font-size: 1.2em;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    font-weight: 100;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: solid #dfdad5de 1px;
  }

  .kontener>button{
    background-color: #04AA6D;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    font-family: 'Open Sans', sans-serif;
    font-weight: 100;
    border-radius: 10px;
    border: solid #04AA6D 1px;
    margin-bottom: 10px;
  }
  
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    padding-top: 60px;
  }
  
  span.psw {
    float: right;
    padding-top: 16px;
  }
  
  .close {
    position: absolute;
    right: 25px;
    top: 0;
    color: #000;
    font-size: 35px;
    font-weight: bold;
  }
  
  .animate {
    -webkit-animation: animatezoom 0.6s;
    animation: animatezoom 0.6s
  }
  
  @-webkit-keyframes animatezoom {
    from {-webkit-transform: scale(0)} 
    to {-webkit-transform: scale(1)}
  }
    
  @keyframes animatezoom {
    from {transform: scale(0)} 
    to {transform: scale(1)}
  }
  
  /* Change styles for span and cancel button on extra small screens */
  @media screen and (max-width: 300px) {
    span.psw {
       display: block;
       float: none;
    }
    .cancelbtn {
       width: 100%;
    }
  }
  
  ::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  
  ::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    -webkit-border-radius: 7px;
    box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
  ::-webkit-scrollbar-button {
    display: none;
    width: 0;
    height: 0;
  }
  
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }


  @media only screen and (min-width: 1300px) { 

    .row{
      width: 100%;
      display: grid;
      grid-template-columns: 5fr 2fr 3fr;
      grid-template-areas: 
      "col1 col2 col3";
      border-bottom: rgb(243, 234, 234) 3px outset;
      padding-bottom: 10px;
      padding-top: 10px;
    }
    .col1{
      margin-top: 10px;
      margin-bottom: 10px;
      /* width: 50%; */
      display: grid;
      grid-area: col1;
      justify-content: start;
      align-items: center;
    }
    .col2{
      /* width: 20%; */
      display: grid;
      grid-area: col2;
      justify-content: space-around;
      align-items: center;
    }
    .col3{
      /* width: 30%; */
      display: flex;
      flex-direction: column;
      grid-area: col3;
      /* grid-auto-flow: row; */
      justify-content: space-around;
      align-items: center;
    }
    .center{
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
      grid-column-start: 1;
      grid-column-end: 4;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
  }

/* telefon */
  @media only screen and (max-width: 1300px) {
/* 
  .pagination{
    display: flex;
    justify-content: center;
  }  */
  
  .row{
    width: 100%;
    display: grid;
    grid-template-areas: 
    "col1 col2"
    "col3 col3";
    grid-template-columns: 2fr 1fr;
    border-bottom: rgb(243, 234, 234) 3px outset;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .col1{
    margin-top: 10px;
    margin-bottom: 10px;
    /* width: 80%; */
    display: grid;
    grid-area: col1;
    flex-direction: column;
    flex-flow: row nowrap;
  }
  .col2{
    /* width: 20%; */
    display: grid;
    grid-area: col2;
    justify-content: space-around;
    align-items: center;
  }
  .col3{
    /* width: 100%; */
    display: flex;
    grid-area: col3;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }
  .center{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    grid-column-start: 1;
    grid-column-end: 4;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  }
  .forma{
    display: block;
    width: 90%;
    margin: auto;
  }

  .checkboxik{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .checkboxik input{
    width: 20px;
    height: 20px;
    margin: 5px;
  }

  .radio-holder{
    width:20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  #paginator{   
    display: flex;
    /* justify-content: center;  */
    overflow-x:auto;
    margin: auto;
    max-width: 90%;
  }

  .pagination {
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
    /* width: auto; */
  }
  .pagination > li {
    display: inline;
  }
  .pagination > li > a,
  .pagination > li > span {
    position: relative;
    padding: 10px 20px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  .pagination > li > a:hover,
  .pagination > li > span:hover,
  .pagination > li > a:focus,
  .pagination > li > span:focus {
    z-index: 2;
    color: #23527c;
    background-color: #eeeeee;
    border-color: #ddd;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
  }
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus {
    color: #777777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
  }
  .pagination-lg > li > a,
  .pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
  }
  .pagination-lg > li:first-child > a,
  .pagination-lg > li:first-child > span {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .pagination-lg > li:last-child > a,
  .pagination-lg > li:last-child > span {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .pagination-sm > li > a,
  .pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
  }
  .pagination-sm > li:first-child > a,
  .pagination-sm > li:first-child > span {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .pagination-sm > li:last-child > a,
  .pagination-sm > li:last-child > span {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }


.page-1 {
  display: grid;
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.5s; /* Firefox < 16 */
   -ms-animation: fadein 1.5s; /* Internet Explorer */
    -o-animation: fadein 1.5s; /* Opera < 12.1 */
       animation: fadein 1.5s;
}
.page-2, .page-3, .page-4, .page-5, .page-6, .page-7, .page-8, .page-9, .page-10, .page-11, .page-12, .page-13, .page-14, .page-15, .page-16, .page-17{
  display: none;
  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.5s; /* Firefox < 16 */
   -ms-animation: fadein 1.5s; /* Internet Explorer */
    -o-animation: fadein 1.5s; /* Opera < 12.1 */
       animation: fadein 1.5s;
}

.logo{
  display: flex;
  margin: 24px 0 12px 0;
  position: relative;
  /* vertical-align: middle; */
  align-items: center;
}

.logoIMG{
  /* display: block; */
  max-width: 60%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

#truck{
  display: flex;
  grid-template-areas: 
  "zderzakL truck zderzakP"
  "oponyLF truck oponyRF"
  "spacerL truck spacerP"
  "oponyLBZ truck oponyRBZ"
  "spacerL2 truck spacerP2"
  "oponyLBW truck oponyRBW";
  height: 65vh;
}

#truck>img{
  display: flex;
  width: auto;
  height: 100%;
}

.samochodIMG{
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
  height: 100%;
  max-height: 50vh;
}

#LF{
  grid-area: oponyLF;
  display: grid;
  height: 100px;
  padding-top: 10%;
  padding-bottom: 10%;

}
#RF{
  grid-area: oponyRF;
  display: grid;
  height: 100px;
  padding-top: 10%;
  padding-bottom: 10%;
}

#LBZ{
  grid-area: oponyLBZ;
  display: grid;
  height: 100px;
  padding-top: 10%;
  padding-bottom: 10%;
}
#LBW{
  grid-area: oponyLBW;
  display: grid;
  height: 100px;
  padding-top: 10%;
  padding-bottom: 10%;
}

#RBZ{
  grid-area: oponyRBZ;
  display: grid;
  height: 100px;
  padding-top: 10%;
  padding-bottom: 10%;
}
#RBW{
  grid-area: oponyRBW;
  display: grid;
  height: 100px;
  padding-top: 10%;
  padding-bottom: 10%;
}

#zderzakL{
  grid-area: zderzakL;
  display: grid;
  text-align: center;
  margin-top: 20%;
  align-items: flex-end;
}
#zderzakP{
  grid-area: zderzakP;
  display: grid;
  text-align: center;
  margin-top: 20%;
  align-items: flex-end;
}

#spacerL{
  grid-area: spacerL;
  display: grid;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: flex-end;
}
#spacerP{
  grid-area: spacerP;
  display: grid;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: flex-end;
}

#spacerL2{
  grid-area: spacerL2;
  display: grid;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: flex-end;
}
#spacerP2{
  grid-area: spacerP2;
  display: grid;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: flex-end;
}

.oponki{
  cursor: pointer;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.file {
  margin:20px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
}

.file input {
  min-width: 14rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}
.file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 2.5rem;
  padding: .5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: .075rem solid #ddd;
  border-radius: .25rem;
  box-shadow: inset 0 .2rem .4rem rgba(0,0,0,.05);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* .file-custom:after {
  content: var(--selectPhoto);
} */

.file-custom:before {
  position: absolute;
  top: -.075rem;
  right: -.075rem;
  bottom: -.075rem;
  z-index: 6;
  display: block;
  content: "Wybierz";
  height: 2.5rem;
  padding: .5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #eee;
  border: .075rem solid #ddd;
  border-radius: 0 .25rem .25rem 0;
}

/* Focus */
.file input:focus ~ .file-custom {
  box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
}

#uwagi{
  width:100%;
}

#uwagi-textarea{
  margin-top: 20px;
  height: 200px;
  width: 100%;
}

.jednostka{
  padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    display: table-cell;
}

.numbery {
  display: table;
}

.numberki{
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;

  text-align: center;
  border: 1px solid #ccc;
  display: table-cell;
}

input[type=radio] {

  padding: 20px;
  margin-left: 20px;
  margin-right: 20px; 

}

#zdjKolumna{
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
  height: 100%;
  max-height: 40px;
}

.kontenerZdj {
  padding: 16px;
  width: 90%;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  
}

#zdjPow{
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 2100px;
  height: auto;
  cursor: pointer;
}

a{
  cursor: pointer;
}

#tytulZdjecia{
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  max-width: 2100px;
  height: auto;
  border-bottom: rgb(243, 234, 234) 3px outset;
}

#wyslij{
  display: none;
}

#wstecz{
  display: none;
}

#wyslano{
  background:rgb(255, 255, 255, 0.8);
  position:absolute;
  flex-direction: column;
  display:flex;
  bottom: -100%;
  height:100%;
  width:100%;
  left:0;
  z-index: 1001;
  animation-name:slideup;
  animation-delay:0.5s;
  animation-duration:1s;
  animation-fill-mode:forwards;
  animation-timing-function:ease-in-out;
}

#wyslano_napis{
  text-align: center;

  font-family: 'Open Sans', sans-serif;
    letter-spacing: 2px;
    word-spacing: 2px;
    text-decoration: none;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    font-size: 30px;
}

#image{
  display: flex;
  justify-content: center;
  width: 100%;
}

#wyslano_image{
  display:flex;
  max-height: 200px;
  margin-top:20vh;
  margin-bottom:5vh;
  max-width: 200px;
  justify-content: center;
}

#buttonik{
  display: flex;
  justify-content: center;
  width: 100%;
}

#wyslano_button{
  background-color: #04AA6D;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
  border-radius: 10px;
  border: solid #04AA6D 1px;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 50px;
  width: 80%;
}

@keyframes slideup {
  0%{bottom:-100%}
  100%{bottom:0;}
}

